import { commonEnv } from './environment.common';

export const environment = {
  ...commonEnv,
  environmentLabel: null,
  activatedTenants: [3550, 3645, 3752, 3751, 3551],
  production: true,
  version: '1.10.73',
  commitHash: '46d6c5451ff1d0456def0ab18f878b435275a945',
  sentry: 'https://622dea0819e449c891cd503b72e2e393@o296516.ingest.sentry.io/5193397',
  zulipUrl: 'https://zulip.mlink.glb.triarc-labs.com',
  zulipBucketUrl:
    'https://digizeit-api.mlink.glb.ch/api/zulip/uploads?token=$UPLOAD_TOKEN&path=',
  // kronosApiUrl: 'https://digizeit-api.mlink.glb.ch',
  kronosApiUrl: 'http://localhost:5000',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'kronos',
    bearerExcludedUrls: ['http://localhost:4200'],
    scope: 'openid email profile offline_access',
    twoFaTimeLimit: 120,
    logoutUrl: 'http://localhost:4200',
  },
  salesOrder: {
    viewDiscountPreAbacus: [],
    viewDiscountPostAbacus: [],
    canEditDiscountPreAbacus: [],
    viewDiscountSpecialPreAbacus: [],
    viewDiscountSpecialPostAbacus: [],
    canEditDiscountSpecialPreAbacus: [],
  },
  showTimeDifferenceOverview: true,
  features: {
    3550: ['time', 'sales', 'purchase', 'chat'],
    3645: ['time', 'sales', 'purchase', 'chat'],
    3752: ['time', 'sales', 'purchase', 'chat'],
    3751: ['time', 'sales', 'purchase', 'chat'],
    3551: ['time', 'sales', 'purchase', 'chat'],
  },
  oneSignal: {
    'digizeit.mlink.glb.ch': {
      appId: 'bdb0000d-ee99-4807-b2aa-c0b0f066b194',
    },
    'my.glb.ch': {
      appId: '9b0fe937-238f-4819-a416-e37520920294',
    },
    'my.bernaroof.ch': {
      appId: '0fad08d1-842e-44e3-8744-414c0efe9a6c',
    },
    'my.bauimpuls.ch': {
      appId: 'ff5c4039-711a-4fe5-a509-6651626f9838',
    },
    'my.allotherm.ch': {
      appId: '9059fbae-6135-4aaf-92d3-031949457de3',
    },
    'my.asag-asbest.ch': {
      appId: 'fe0559d4-28b3-4983-aa7e-4af9cfa7fad1',
    },
  },
  myAbacus:
    'https://myabacus.glb-gruppe.ch:40001/oauth/oauth2/v1/auth?response_type=code&client_id=myabacus&redirect_uri=%2Fportal%2Fmyabacus%2Foauthcb&scope=abacus+openid&state=C4A6PY8KFLVXMJMM&response_mode=form_post',
  customerHub: 'https://kunde.glbgruppe.ch',
};
