/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExecuteFormActionDto } from '../model/executeFormActionDto';
import { ExecuteFormActionResponseDto } from '../model/executeFormActionResponseDto';
import { FormEmailDto } from '../model/formEmailDto';
import { FormInstanceDto } from '../model/formInstanceDto';
import { SaveFormDetailDto } from '../model/saveFormDetailDto';
import { SaveFormDto } from '../model/saveFormDto';
import { SendFormEmailRequestDto } from '../model/sendFormEmailRequestDto';
import { UploadPolicy } from '../model/uploadPolicy';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PublicFormsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public executeFormAction(body?: ExecuteFormActionDto, observe?: 'body', reportProgress?: boolean): Promise<ExecuteFormActionResponseDto>;
    public executeFormAction(body?: ExecuteFormActionDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ExecuteFormActionResponseDto>>;
    public executeFormAction(body?: ExecuteFormActionDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ExecuteFormActionResponseDto>>;
    public executeFormAction(body?: ExecuteFormActionDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/public-forms/action`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'body', reportProgress?: boolean): Promise<string>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<string>>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<string>>;
    public getDownloadRequest(formId: string, fieldId: string, filename: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getDownloadRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getDownloadRequest.');
        }
        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling getDownloadRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/public-forms/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/${encodeURIComponent(String(filename))}/download/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getForm(id: string, observe?: 'body', reportProgress?: boolean): Promise<FormInstanceDto>;
    public getForm(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormInstanceDto>>;
    public getForm(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormInstanceDto>>;
    public getForm(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getForm.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/public-forms/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formDefId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPreview(formDefId: string, observe?: 'body', reportProgress?: boolean): Promise<FormInstanceDto>;
    public getPreview(formDefId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormInstanceDto>>;
    public getPreview(formDefId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormInstanceDto>>;
    public getPreview(formDefId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formDefId === null || formDefId === undefined) {
            throw new Error('Required parameter formDefId was null or undefined when calling getPreview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/public-forms/preview/${encodeURIComponent(String(formDefId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formId 
     * @param fieldId 
     * @param fileName 
     * @param filetype 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadFileRequest(formId: string, fieldId: string, fileName: string, filetype: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public getUploadFileRequest(formId: string, fieldId: string, fileName: string, filetype: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public getUploadFileRequest(formId: string, fieldId: string, fileName: string, filetype: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public getUploadFileRequest(formId: string, fieldId: string, fileName: string, filetype: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling getUploadFileRequest.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling getUploadFileRequest.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling getUploadFileRequest.');
        }
        if (filetype === null || filetype === undefined) {
            throw new Error('Required parameter filetype was null or undefined when calling getUploadFileRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/public-forms/${encodeURIComponent(String(formId))}/${encodeURIComponent(String(fieldId))}/${encodeURIComponent(String(fileName))}/${encodeURIComponent(String(filetype))}/upload/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formInstanceId 
     * @param fileName 
     * @param filetype 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadFormRequest(formInstanceId: string, fileName: string, filetype: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public getUploadFormRequest(formInstanceId: string, fileName: string, filetype: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public getUploadFormRequest(formInstanceId: string, fileName: string, filetype: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public getUploadFormRequest(formInstanceId: string, fileName: string, filetype: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formInstanceId === null || formInstanceId === undefined) {
            throw new Error('Required parameter formInstanceId was null or undefined when calling getUploadFormRequest.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling getUploadFormRequest.');
        }
        if (filetype === null || filetype === undefined) {
            throw new Error('Required parameter filetype was null or undefined when calling getUploadFormRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/public-forms/${encodeURIComponent(String(formInstanceId))}/${encodeURIComponent(String(fileName))}/${encodeURIComponent(String(filetype))}/upload/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param formInstanceId 
     * @param signatureDefinitionId 
     * @param fileName 
     * @param filetype 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadSignatureRequest(formInstanceId: string, signatureDefinitionId: string, fileName: string, filetype: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public getUploadSignatureRequest(formInstanceId: string, signatureDefinitionId: string, fileName: string, filetype: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public getUploadSignatureRequest(formInstanceId: string, signatureDefinitionId: string, fileName: string, filetype: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public getUploadSignatureRequest(formInstanceId: string, signatureDefinitionId: string, fileName: string, filetype: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (formInstanceId === null || formInstanceId === undefined) {
            throw new Error('Required parameter formInstanceId was null or undefined when calling getUploadSignatureRequest.');
        }
        if (signatureDefinitionId === null || signatureDefinitionId === undefined) {
            throw new Error('Required parameter signatureDefinitionId was null or undefined when calling getUploadSignatureRequest.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling getUploadSignatureRequest.');
        }
        if (filetype === null || filetype === undefined) {
            throw new Error('Required parameter filetype was null or undefined when calling getUploadSignatureRequest.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/public-forms/${encodeURIComponent(String(formInstanceId))}/${encodeURIComponent(String(signatureDefinitionId))}/${encodeURIComponent(String(fileName))}/${encodeURIComponent(String(filetype))}/upload/request`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveForm(body?: SaveFormDto, observe?: 'body', reportProgress?: boolean): Promise<FormInstanceDto>;
    public saveForm(body?: SaveFormDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormInstanceDto>>;
    public saveForm(body?: SaveFormDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormInstanceDto>>;
    public saveForm(body?: SaveFormDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/public-forms`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveFormDetail(body?: SaveFormDetailDto, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public saveFormDetail(body?: SaveFormDetailDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public saveFormDetail(body?: SaveFormDetailDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public saveFormDetail(body?: SaveFormDetailDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/public-forms/saveDetail`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendFormEmail(body?: SendFormEmailRequestDto, observe?: 'body', reportProgress?: boolean): Promise<FormEmailDto>;
    public sendFormEmail(body?: SendFormEmailRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<FormEmailDto>>;
    public sendFormEmail(body?: SendFormEmailRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<FormEmailDto>>;
    public sendFormEmail(body?: SendFormEmailRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/public-forms/sendFormEmail`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
