


export type InventoryState = 'Open' | 'Closed';

export const InventoryState = {
    Open: 'Open' as InventoryState,
    Closed: 'Closed' as InventoryState
};

